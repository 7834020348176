import { createFeature, createReducer, on } from "@ngrx/store";
import laodingInitialState from "./loading.state";
import { LoadingActions } from "./actions";
import { loadingReducers } from "./reducers";

const LOADING_FEATURE_NAME = "loading";

export const loadingFeature = createFeature({
  name: LOADING_FEATURE_NAME,
  reducer: createReducer(
    laodingInitialState,
    on(LoadingActions.startLoading, loadingReducers.startLoading),
    on(LoadingActions.stopLoading, loadingReducers.stopLoading)
  ),
});

export const loadingSlice = {
  name: loadingFeature.name,
  reducer: loadingFeature.reducer,
} as const;
