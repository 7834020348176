export interface LoadingState {
  isLoading: boolean;
}

const startLoading = (state: LoadingState) => ({ ...state, isLoading: true });
const stopLoading = (state: LoadingState) => ({ ...state, isLoading: false });

export const loadingReducers = {
  startLoading,
  stopLoading,
};
